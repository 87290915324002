<template>
  <div class="with-nav-bar">
    <van-nav-bar
      title="充电枪详情"
      left-text="返回"
      :right-text="!state.loading ? '刷新' : ''"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
      @click-right="loadPage"
    />
    <van-loading v-if="state.loading" class="mt-3 text-center" vertical
      >加载中</van-loading
    >
    <van-index-bar v-else-if="state.items.length > 0" :index-list="[]">
      <div v-for="(item, index) in state.items" :key="index">
        <van-index-anchor
          :index="
            item.gun_number == 0 ? '主枪' : item.gun_number == 1 ? 'A枪' : 'B枪'
          "
        />
        <van-cell title="连枪状态">
          <template #value>
            <van-tag v-if="item.connection_status == 0">未连接</van-tag>
            <van-tag v-else type="success" plain>已连接</van-tag>
          </template>
        </van-cell>
        <van-cell
          v-if="item.work_status != 2"
          title="工作状态"
          :value="workStatus(item.work_status)"
        />
        <van-cell
          v-else
          title="工作状态"
          is-link
          @click="stopCharging(item.order_id)"
        >
          <template #value>
            <span class="text-success">{{ workStatus(item.work_status) }}</span>
          </template>
        </van-cell>
        <van-cell title="警告代码" :value="item.warning_code" />
        <van-cell
          title="订单标识"
          :value="item.order_id ? item.order_id : '无'"
        />
        <div v-if="item.work_status == 2 || item.work_status == 3">
          <van-cell
            title="本次电量"
            :value="`${new Number(item.total_power * 0.01).toFixed(2)}kW·h`"
          />
          <van-cell
            title="本次费用"
            :value="`${new Number(item.total_fee * 0.01).toFixed(2)}元`"
          />
        </div>
      </div>
    </van-index-bar>
    <van-empty v-else image="network" description="暂无充电枪" />
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, reactive } from "vue";
import { Dialog, List, Tag, Toast, IndexBar, IndexAnchor } from "vant";
import { getGunList } from "@/api/device.service";
import { useRoute, useRouter } from "vue-router";
import { stopPowerRecharge } from "@/api/device.service";
export default {
  components: {
    [Tag.name]: Tag,
    [List.name]: List,
    [IndexBar.name]: IndexBar,
    [IndexAnchor.name]: IndexAnchor,
  },
  setup() {
    let timer = null;
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      device_id: 0,
      device: {},
      items: [],
      loading: true,
    });

    const isWorking = computed(() => {
      for (const i in state.items) {
        if (state.items[i].work_status == 2) {
          return true;
        }
      }
      return false;
    });

    const loadPage = async (showtoast = true) => {
      clearInterval(timer);
      if (!state.loading && showtoast) {
        Toast.loading({ message: "加载中", duration: 0, forbidClick: true });
      }
      try {
        const { result } = await getGunList(state.device_id);
        state.items = [];
        state.items.push(...result.items);
        state.device = result.device;
        state.loading = false;
        if (showtoast) {
          Toast.clear();
        }
        if (isWorking.value) {
          timer = setInterval(() => {
            loadPage(false);
          }, 5000);
        }
      } catch (error) {
        state.loading = false;
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const stopCharging = (order_id) => {
      Dialog.confirm({
        title: "结束充电",
        message: "请确认是否结束充电?",
      })
        .then(() => {
          processStop(order_id);
        })
        .catch(() => {});
    };

    const processStop = async (order_id) => {
      state.disableStopCharging = true;
      Toast.loading({
        message: "正在结束充电",
        duration: 0,
        forbidClick: true,
      });
      try {
        await stopPowerRecharge(order_id);
        setTimeout(() => {
          Toast.clear();
          loadPage();
        }, 1000);
      } catch (error) {
        Toast.clear();
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const workStatus = (status) => {
      const allStatus = [
        "空闲中",
        "空闲中",
        "充电中",
        "已结束",
        "启动失败",
        "已预约",
        "系统故障",
        "被占用",
      ];
      if (status < 0 || status > 7) {
        return "未知";
      } else {
        return allStatus[status];
      }
    };

    onMounted(() => {
      const { id } = route.query;
      if (!id) {
        Toast.fail("参数错误");
        router.back();
      } else {
        state.device_id = id;
        loadPage();
      }
    });

    onUnmounted(() => {
      try {
        clearInterval(timer);
      } catch (error) {
        // ...
      }
    });

    return { state, loadPage, workStatus, stopCharging };
  },
};
</script>
